/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

// The list of all used GraphQl queries
import gql from "graphql-tag";

// Pattern:
// Query
// Mutation

// Items
export const GET_INVENTORIES = gql`
  query GetInventories($vendorId: String!) {
    getItems(vendorId: $vendorId) {
      itemId
      code
      title
      mcwItem {
        code
      }
      manufactureName
      manufacturePartNumber
      hide
      keywords
      metaDescription
      pageTitle
      urlLocked
      seoUrl
      itemType
      itemImage
      thumbnail
      smallImage
      inventories {
        inventoryId
        inStock
        inStockBuffer
        hide
        onOrder
        minOrderQty
        minReorderQty
        pickPackDays
        hide
        enableEditDeliveryDate
        discontinued
        modifyTime
        thirdpartyCode
        thirdpartyInventory
      }
    }
  }
`;

export const GET_ITEMS = gql`
  query GetItems($vendorId: String!) {
    getItems(vendorId: $vendorId) {
      itemId
      code
      title
    }
  }
`;

export const GET_LIST_OF_ITEMS = gql`
  query GetListOfItems($vendorId: String!) {
    getItems(vendorId: $vendorId, beginIndex: 0, maxResult: 10) {
      itemId
      code
      title
      mcwItem {
        code
      }
      manufactureName
      manufacturePartNumber
      hide
      keywords
      metaDescription
      pageTitle
      urlLocked
      seoUrl
      itemType
      itemImage
      thumbnail
      smallImage
      inventories {
        inventoryId
        inStock
        inStockBuffer
        hide
        onOrder
        minOrderQty
        minReorderQty
        pickPackDays
        hide
        enableEditDeliveryDate
        discontinued
        modifyTime
        thirdpartyCode
        thirdpartyInventory
      }
      offerPrices {
        id
        price1
        offerCode
        listPrice
        default
        startDate
        endDate
        priority
        onSale
        map
        points
        shopperGroupId
        setup
        currency {
          billingCurrencyCode
          sign
          a3code
        }
      }
      storeCode {
        code
        codeName
      }
    }
    getStoreCodes(vendorId: $vendorId) {
      code
      codeName
    }
  }
`;

export const GET_ITEMS_BY_CODE_OR_TITLE = gql`
  query GetItemsByCodeOrTitle($vendorId: String!, $codeOrTitle: String!) {
    getItemsByCodeOrTitle(vendorId: $vendorId, codeOrTitle: $codeOrTitle) {
      itemId
      code
      title
      weight
      length
      width
      height
      shortDesc
      longDesc
      shipType
      taxable
      weightType
      lengthType
      longDesc2
      longDesc3
      longDesc4
      longDesc5
      specialDesc
      modifyTime
      creatorId
      manufactureName
      manufacturePartNumber
      hide
      preOrder
      publishState
      livePublishState
      startDate
      endDate
      available
      keywords
      metaDescription
      pageTitle
      urlLocked
      seoUrl
      itemType
      itemImage
      thumbnail
      largeImage5
      smallImage
      onSaleImage
      lpItemImage
      lpLargeImage
      seoHeadline
      copyTime
      mcwUpdate
      itemTheme
      shipType
      baseCategory {
        categoryId
        name
      }
      properties {
        vendorId
        propertyName
        propertyValue
      }
      offerPrices {
        price1
        offerCode
        listPrice
        default
        startDate
        endDate
        priority
        onSale
        map
        points
        shopperGroupId
        setup
        currency {
          sign
          a3code
        }
      }
      inventories {
        inventoryId
        inStock
      }
    }
  }
`;

export const GET_ITEM_BY_CODE = gql`
  query GetItemByCode($vendorId: String!, $code: String!) {
    getItemByCode(vendorId: $vendorId, code: $code) {
      itemId
      code
      title
      weight
      length
      width
      height
      shortDesc
      longDesc
      shipType
      taxable
      weightType
      lengthType
      longDesc2
      longDesc3
      longDesc4
      longDesc5
      specialDesc
      modifyTime
      creatorId
      manufactureName
      manufacturePartNumber
      hide
      preOrder
      publishState
      livePublishState
      startDate
      endDate
      available
      keywords
      metaDescription
      pageTitle
      urlLocked
      seoUrl
      itemType
      itemImage
      thumbnail
      largeImage5
      smallImage
      onSaleImage
      lpItemImage
      lpLargeImage
      seoHeadline
      copyTime
      mcwUpdate
      itemTheme
      shipType
      baseCategory {
        categoryId
        name
      }
      properties {
        vendorId
        propertyName
        propertyValue
      }
      multiValueProperties {
        propertyValueId
        propertyValue
        propertyNameId
        propertyName
      }
      offerPrices {
        id
        price1
        offerCode
        listPrice
        default
        startDate
        endDate
        priority
        onSale
        map
        points
        shopperGroupId
        setup
        currency {
          currencyId
          sign
          a3code
        }
      }
      inventories {
        inventoryId
        inStock
      }
      storeCode {
        code
        codeName
      }
    }
    getMultiValuePropertyNames(vendorId: $vendorId) {
      vendorId
      propertyNameId
      propertyName
      propertyValues {
        propertyValueId
        propertyValue
        propertyNameId
        propertyName
      }
    }
    getCouriergroups(vendorId: $vendorId) {
      id
      groupName
      defaultGroup
    }
    getStoreCodes(vendorId: $vendorId) {
      code
      codeName
    }
    getCategories(vendorId: $vendorId) {
      categoryId
      name
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct(
    $itemId: ID!
    $vendorId: String!
    $code: String!
    $title: String
    $shortDesc: String
    $origCategoryId: Int
    $longDesc: String
    $shipType: String
    $taxable: Int
    $weightType: Int
    $lengthType: Int
    $weight: Float
    $length: Float
    $width: Float
    $height: Float
    $startDate: String
    $endDate: String
    $available: Boolean
    $keywords: String
    $metaDescription: String
    $seoHeadline: String
    $pageTitle: String
    $urlLocked: Boolean
    $seoUrl: String
    $itemType: Int
    $storeCode: Int
  ) {
    updateItem(
      itemId: $itemId
      item: {
        vendorId: $vendorId
        code: $code
        title: $title
        shortDesc: $shortDesc
        origCategoryId: $origCategoryId
        longDesc: $longDesc
        shipType: $shipType
        taxAble: $taxable
        weightType: $weightType
        lengthType: $lengthType
        weight: $weight
        length: $length
        width: $width
        height: $height
        startDate: $startDate
        endDate: $endDate
        available: $available
        keywords: $keywords
        metaDescription: $metaDescription
        seoHeadline: $seoHeadline
        pageTitle: $pageTitle
        urlLocked: $urlLocked
        seoUrl: $seoUrl
        itemType: $itemType
        storeCode: $storeCode
      }
    ) {
      code
      title
      shortDesc
      longDesc
      shipType
      taxable
      weightType
      lengthType
      weight
      length
      width
      height
      itemImage
      thumbnail
      largeImage5
      smallImage
      startDate
      endDate
      available
      keywords
      metaDescription
      seoHeadline
      pageTitle
      urlLocked
      seoUrl
      itemType
      #      storeCode
    }
  }
`;

export const CREATE_ITEM = gql`
  mutation CreateItem(
    $vendorId: String!
    $code: String!
    $origCategoryId: Int!
    $title: String!
  ) {
    createItem(
      item: {
        vendorId: $vendorId
        code: $code
        origCategoryId: $origCategoryId
        title: $title
      }
    ) {
      itemId
      code
      baseCategory {
        name
        categoryId
      }
    }
  }
`;

export const DELETE_ITEM = gql`
  mutation DeleteItem($vendorId: String!, $itemIds: [ID]!) {
    deleteItem(vendorId: $vendorId, itemIds: $itemIds)
  }
`;

// Categories
export const GET_CATEGORIES = gql`
  query GetCategories($vendorId: String!) {
    getCategories(vendorId: $vendorId) {
      categoryId
      name
      pageTitle
      metaKeywords
      metaDescription
      seoUrl
    }
  }
`;
export const GET_CATEGORY_NAMES = gql`
  query GetCategoryNames($vendorId: String!) {
    getCategories(vendorId: $vendorId) {
      categoryId
      name
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $categoryId: ID!
    $vendorId: String!
    $categoryName: String!
    $pageTitle: String
    $metaKeywords: String
    $metaDescription: String
    $seoURL: String
  ) {
    updateCategory(
      categoryId: $categoryId
      category: {
        vendorId: $vendorId
        name: $categoryName
        pageTitle: $pageTitle
        metaKeywords: $metaKeywords
        metaDescription: $metaDescription
        seoUrl: $seoURL
      }
    ) {
      categoryId
      name
      pageTitle
      metaKeywords
      metaDescription
      seoUrl
    }
  }
`;

// Inventory
export const GET_INVENTORY_BY_ITEM_ID = gql`
  query GetInventoryByItemId($vendorId: String!, $itemId: ID!) {
    getInventoryByItemId(vendorId: $vendorId, itemId: $itemId) {
      inventoryId
    }
  }
`;

export const CREATE_INVENTORY = gql`
  mutation CreateInventory(
    $vendorId: String!
    $code: String!
    $itemId: ID!
    $onOrder: Int
    $minReorderQty: Int
    $pickPackDays: Int
    $thirdpartyCode: String
    $dropShipMinQty: Int
    $dropShipDays: Int
    $minOrderQty: Int
    $nextShipQty: Int
    $hide: Boolean
    $enableEditDeliveryDate: Boolean
    $discontinued: Boolean
    $thirdpartyInventory: Boolean
    $nextShipDate: String
  ) {
    createInventory(
      inventory: {
        vendorId: $vendorId
        code: $code
        itemId: $itemId
        onOrder: $onOrder
        minReorderQty: $minReorderQty
        pickPackDays: $pickPackDays
        thirdpartyCode: $thirdpartyCode
        dropShipMinQty: $dropShipMinQty
        dropShipDays: $dropShipDays
        minOrderQty: $minOrderQty
        nextShipQty: $nextShipQty
        hide: $hide
        enableEditDeliveryDate: $enableEditDeliveryDate
        discontinued: $discontinued
        thirdpartyInventory: $thirdpartyInventory
        nextShipDate: $nextShipDate
      }
    ) {
      inStock
    }
  }
`;
export const UPDATE_INVENTORY = gql`
  mutation UpdateInventory(
    $inventoryId: ID!
    $vendorId: String!
    $code: String!
    $itemId: ID!
    $requestStock: Int
    $requestAction: Int
    $inStockBuffer: Int
    $onOrder: Int
    $minReorderQty: Int
    $pickPackDays: Int
    $thirdpartyCode: String
    $dropShipMinQty: Int
    $dropShipDays: Int
    $minOrderQty: Int
    $nextShipQty: Int
    $hide: Boolean
    $enableEditDeliveryDate: Boolean
    $discontinued: Boolean
    $thirdpartyInventory: Boolean
    $nextShipDate: String
  ) {
    updateInventory(
      inventoryId: $inventoryId
      inventory: {
        vendorId: $vendorId
        code: $code
        itemId: $itemId
        requestStock: $requestStock
        requestAction: $requestAction
        inStockBuffer: $inStockBuffer
        onOrder: $onOrder
        minReorderQty: $minReorderQty
        pickPackDays: $pickPackDays
        thirdpartyCode: $thirdpartyCode
        dropShipMinQty: $dropShipMinQty
        dropShipDays: $dropShipDays
        minOrderQty: $minOrderQty
        nextShipQty: $nextShipQty
        hide: $hide
        enableEditDeliveryDate: $enableEditDeliveryDate
        discontinued: $discontinued
        thirdpartyInventory: $thirdpartyInventory
        nextShipDate: $nextShipDate
      }
    ) {
      itemId
      inStock
      modifyTime
    }
  }
`;

export const DELETE_INVENTORY = gql`
  mutation DeleteInventory($inventoryIds: [ID]!) {
    deleteInventory(inventoryIds: $inventoryIds)
  }
`;

// Offer Prices
export const GET_OFFER_PRICES = gql`
  query GetOfferPrices($vendorId: String!) {
    getOfferPrices(vendorId: $vendorId) {
      id
      itemCode
      priority
      shopperGroupId
      offerCode
      startDate
      endDate
      listPrice
      price1
      map
      setup
      points
      default
      currency {
        currencyId
        a3code
        sign
      }
      supplier
      modifyTime
    }
  }
`;

export const UPDATE_PRICE = gql`
  mutation UpdatePrice(
    $vendorId: String!
    $priceId: ID!
    $itemCode: String!
    $listPrice: Float
    $price1: Float!
    $itemId: ID!
    $currencyId: ID!
  ) {
    updateOfferPrice(
      priceId: $priceId
      offerPrice: {
        vendorId: $vendorId
        itemCode: $itemCode
        price1: $price1
        listPrice: $listPrice
        itemId: $itemId
        currencyId: $currencyId
      }
    ) {
      id
    }
  }
`;

export const DELETE_OFFER_PRICE = gql`
  mutation DeleteOfferPrice($priceIds: [ID]!) {
    deleteOfferPrice(priceIds: $priceIds)
  }
`;

export const CREATE_SALE_PRICE = gql`
  mutation CreateSalePrice(
    $vendorId: String!
    $itemCode: String!
    $itemId: ID!
    $currencyId: ID!
    $startDate: String
    $endDate: String
    $listPrice: Float
    $price1: Float!
  ) {
    createOfferPrice(
      offerPrice: {
        vendorId: $vendorId
        itemCode: $itemCode
        itemId: $itemId
        currencyId: $currencyId
        onSale: 1
        def: "N"
        startDate: $startDate
        endDate: $endDate
        listPrice: $listPrice
        price1: $price1
      }
    ) {
      id
    }
  }
`;

// Multivalued Properties - MVP
export const DELETE_MVP_FOR_ITEM = gql`
  mutation deleteMultiValuePropertyForItem(
    $itemId: ID!
    $propertyValueIds: [ID]!
  ) {
    deleteMultiValuePropertyForItem(
      itemId: $itemId
      propertyValueIds: $propertyValueIds
    )
  }
`;

export const ADD_MVP_FOR_ITEM = gql`
  mutation addMultiValuePropertyForItem(
    $itemId: ID!
    $propertyValueIds: [ID]!
  ) {
    addMultiValuePropertyForItem(
      itemId: $itemId
      propertyValueIds: $propertyValueIds
    )
  }
`;

// Item Properties
export const GET_PROPERTY_DEFINITION = gql`
  query GetPropertyDefinition($vendorId: String!, $propName: String!) {
    getPropertyDefinition(vendorId: $vendorId, propName: $propName) {
      id
      name
      propertyOptions {
        id
        value
      }
    }
  }
`;

// Store Codes. Ex: New Items, Properties Imported, Product is Complete etc.
export const GET_PRODUCT_STATUSES = gql`
  query GetProductStatuses($vendorId: String!) {
    getStoreCodes(vendorId: $vendorId) {
      code
      codeName
    }
  }
`;

// Courier Group
export const GET_COURIER_GROUP = gql`
  query GetCourierGroup($vendorId: String!) {
    getCouriergroups(vendorId: $vendorId) {
      id
      groupName
      defaultGroup
    }
  }
`;
