/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */
import React, { lazy, useEffect, useState } from "react";
import { Row, Col } from "antd";
import ProductSectionComponent from "./SimpleEditProductMode/ProductSectionComponent";
import PricingInventoryComponent from "./SimpleEditProductMode/PricingInventoryComponent";
import ImagesSectionComponent from "./SimpleEditProductMode/ImagesSectionComponent";
import CategorizationComponent from "./SimpleEditProductMode/CategorizationComponent";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GET_ITEM_BY_CODE,
  GET_PROPERTY_DEFINITION
} from "../../../../../graphql/graphql_queries";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateProduct } from "../../../../../redux/actions/productStateAction";
import ServerIsNotAvailableComponent from "../../../../SharedComponents/ServerIsNotAvailableComponent";
import { Redirect } from "react-router-dom";

const PriceComponent = lazy(() =>
  import("./AdvancedEditProductMode/Tabs/ProductData/PriceComponent")
);
const AvailabilityComponent = lazy(() =>
  import("./SimpleEditProductMode/AvailabilityComponent")
);
const PromotionsComponent = lazy(() =>
  import("./AdvancedEditProductMode/Tabs/ProductData/PromotionsComponent")
);
const DimensionsComponent = lazy(() =>
  import("./AdvancedEditProductMode/Tabs/ProductData/DimensionsComponent")
);
const SettingsComponent = lazy(() =>
  import("./SimpleEditProductMode/SettingsComponent")
);

export default function MainProductTabComponent({ viewMode, itemCode }) {
  const dispatch = useDispatch();
  const { vendorId } = useSelector(
    state => state.configProviderReducer.authState,
    shallowEqual
  );
  const [rowData, setRowData] = useState({
    storeCode: { code: "" },
    multiValueProperties: []
  });
  const [multiVProperties, setMultiVProperties] = useState([]);
  const [courierGroups, setCourierGroups] = useState([]);
  const [productStatuses, setProductStatuses] = useState([]);
  const [listOfCategoryNames, setListOfCategoryNames] = useState([]);

  const { loading, error, data } = useQuery(GET_ITEM_BY_CODE, {
    variables: {
      vendorId,
      code: itemCode
    },
    skip: typeof vendorId === "undefined",
    pollInterval: process.env.REACT_APP_POLLINTERVAL
  });

  useEffect(
    () => {
      if (data && !error && !loading) {
        // GraphQL says that itemId is supposed to be ID datatype but server returns a string.
        // So, we are converting it the Int
        //rowData.itemId = parseInt(rowData.itemId);
        setRowData(data.getItemByCode);
        setMultiVProperties(data.getMultiValuePropertyNames);
        setCourierGroups(data.getCouriergroups);
        setProductStatuses(data.getStoreCodes);
        setListOfCategoryNames(data.getCategories);
      }

      dispatch(
        updateProduct({
          ...rowData,
          storeCode: parseInt(rowData.storeCode && rowData.storeCode.code)
        })
      );
    },
    [data, rowData]
  );

  if (rowData) {
    return (
      <>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={17} lg={17} xl={17}>
            <ProductSectionComponent
              listOfBrands={multiVProperties.find(
                obj => obj.propertyName === process.env.REACT_APP_BRAND
              )}
              listOfCategories={listOfCategoryNames}
            />

            <br />
            <PricingInventoryComponent {...rowData} />
            {/*<PriceComponent*/}
            {/*  props={{*/}
            {/*    offerPrice: rowData.offerPrices,*/}
            {/*    inventory: rowData.inventories*/}
            {/*  }}*/}
            {/*/>*/}
          </Col>
          <Col xs={24} sm={24} md={7} lg={7} xl={7}>
            <ImagesSectionComponent
              mainImage={rowData.itemImage}
              additionalImages={rowData.largeImage5}
            />

            <br />
            <CategorizationComponent
              itemId={rowData.itemId}
              selectedCollections={rowData.multiValueProperties.filter(
                obj => obj.propertyName === process.env.REACT_APP_COLLECTION
              )}
              selectedCategories={rowData.multiValueProperties.filter(
                obj => obj.propertyName === process.env.REACT_APP_CATEGORY
              )}
              selectedTags={rowData.multiValueProperties.filter(
                obj => obj.propertyName === process.env.REACT_APP_TAG
              )}
              listOfCollections={multiVProperties.find(
                obj => obj.propertyName === process.env.REACT_APP_COLLECTION
              )}
              listOfCategories={multiVProperties.find(
                obj => obj.propertyName === process.env.REACT_APP_CATEGORY
              )}
              listOfTags={multiVProperties.find(
                obj => obj.propertyName === process.env.REACT_APP_TAG
              )}
            />
            <br />
            <AvailabilityComponent
              props={{
                listOfProductStatuses: productStatuses
              }}
            />
            <PromotionsComponent {...rowData} />
            <DimensionsComponent viewMode={{ record: rowData }} />
            <br />
            <SettingsComponent courierGroups={courierGroups} />
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/app/products"
        }}
      />
    );
  }
}
//MainProductTabComponent.whyDidYouRender = true;
