/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import axios from "axios";

class Auth {
  constructor() {
    this.adminType = "";
    this.supplierVendorId = "";
    this.authenticated = false;
  }

  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb) {
    axios({
      url: process.env.REACT_APP_SERVER_PREVIEW + `logout.admin`,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    })
      .then(() => (this.authenticated = false))
      .catch(err => {
        console.log("Logout error", err);
        this.authenticated = false;
      });
    cb();
  }

  async isAuthenticated(callback) {
    const { data } = await axios({
      url: process.env.REACT_APP_SERVER_PREVIEW + `reactadmin/loginstatus`,
      method: "get",
      withCredentials: true,

      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    });
    this.authenticated = data.success;
    callback(data.success);
    return data;
  }

  async getAdminType() {
    const { data } = await axios({
      url: process.env.REACT_APP_SERVER_PREVIEW + `reactadmin/loginstatus`,
      method: "get",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      responseType: "json"
    });

    return (this.adminType = data.adminType);
  }

  async getVendorId() {
    const { data } = await axios({
      url: process.env.REACT_APP_SERVER_PREVIEW + `reactadmin/loginstatus`,
      method: "get",
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    });

    return (this.supplierVendorId = data.vendors[0].vendorId);
  }
}

export default new Auth();
