import React from "react";
import { Menu, Row, Col, Breadcrumb } from "antd";
import { Link } from "react-router-dom";

export default function MenuComponent() {
  const { SubMenu } = Menu;
  return (
    <Row>
      <Col span={24}>
        <Menu mode="horizontal" style={{ padding: "0 .7rem" }}>
          {/*<Menu.Item key="mail">*/}
          {/*  <Link to="/app/dashboard">*/}
          {/*    /!* <LikeOutlined type="home" /> *!/*/}
          {/*    Dashboard*/}
          {/*  </Link>*/}
          {/*</Menu.Item>*/}
          <SubMenu
            title={<span className="submenu-title-wrapper">Orders</span>}
          >
            <Menu.Item key="setting:1">
              <Link to="/app/manage-orders">Manage Orders</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            title={<span className="submenu-title-wrapper">Catalog</span>}
          >
            <Menu.Item key="setting:2">
              <Link to="/app/prices">Prices</Link>
            </Menu.Item>
            <Menu.Item key="setting:3">
              <Link to="/app/inventory">Inventory</Link>
            </Menu.Item>
            {/*<Menu.Item key="setting:4">*/}
            {/*  <Link to="/app/category">Category</Link>*/}
            {/*</Menu.Item>*/}
            <Menu.Item key="setting:123">
              <Link to="/app/products">My Products</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Col>
    </Row>
  );
}
