/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */
import React from 'react'
import {
    Collapse,
    Row,
    Col,
    Select,
    Form,
    Switch,
    DatePicker,
    Table,
} from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
const { Panel } = Collapse
const { Option } = Select
const { RangePicker } = DatePicker

export default function PropertiesTabComponent() {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Age',
            dataIndex: 'age',
        },
        {
            title: 'Address',
            dataIndex: 'address',
        },
    ]
    const data = []
    return (
        <>
            <Row>
                <Col span={24}>
                    <Collapse expandIconPosition="right">
                        <Panel header="Product: " key="1">
                            <Row gutter={[20, 20]}>
                                <Col span={24}>
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        size="small"
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
        </>
    )
}
