/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Typography,
  Row,
  Col,
  Button,
  Radio,
  Form,
  Statistic,
  Tabs,
  Divider,
  Popconfirm,
  message
} from "antd";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DeleteFilled } from "@ant-design/icons";
import { switchEditMode } from "../../../../../redux/actions/editItemActions";
import MainProductTabComponent from "./MainProductTabComponent";
import ContentBlockTabComponent from "./AdvancedEditProductMode/Tabs/ContentBlocks/ContentBlockTabComponent";
import PropertiesTabComponent from "./AdvancedEditProductMode/Tabs/Properties/PropertiesTabComponent";
import VideoTabComponent from "./AdvancedEditProductMode/Tabs/Video/VideoTabComponent";
import VariationsTabComponent from "./AdvancedEditProductMode/Tabs/Variations/VariationsTabComponent";
import TagsTabComponent from "./AdvancedEditProductMode/Tabs/Tags/TagsTabComponent";
import { useMutation } from "@apollo/react-hooks";
import {
  UPDATE_INVENTORY,
  UPDATE_PRODUCT,
  UPDATE_PRICE,
  GET_LIST_OF_ITEMS,
  DELETE_ITEM,
  DELETE_OFFER_PRICE,
  CREATE_SALE_PRICE,
  GET_CATEGORIES,
  GET_ITEM_BY_CODE,
  ADD_MVP_FOR_ITEM,
  DELETE_MVP_FOR_ITEM,
  CREATE_INVENTORY
} from "../../../../../graphql/graphql_queries";
import { openNotificationWithIcon } from "../../../../SharedComponents/notifications/notifications";
import { useHistory } from "react-router-dom";
const { TabPane } = Tabs;

export default function EditProductComponent({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { vendorId } = useSelector(
    state => state.configProviderReducer.authState,
    shallowEqual
  );
  const { product } = useSelector(
    state => state.productStateReducer,
    shallowEqual
  );
  const { inventory, price, salePriceOnDelete, setSalePrice } = useSelector(
    state => state.pricingAndInventoryReducer,
    shallowEqual
  );
  const viewMode = useSelector(
    state => state.editItemReducer.viewMode,
    shallowEqual
  );

  const [saveOrUpdateProductMutation] = useMutation(UPDATE_PRODUCT);
  const [updateInventory] = useMutation(UPDATE_INVENTORY);
  const [createInventory] = useMutation(CREATE_INVENTORY);
  const [updatePrice] = useMutation(UPDATE_PRICE);
  const [deleteItemMutation] = useMutation(DELETE_ITEM);
  const [deleteSalePriceMutation] = useMutation(DELETE_OFFER_PRICE);
  const [addMVPForItem] = useMutation(ADD_MVP_FOR_ITEM);
  const [deleteMVPForItem] = useMutation(DELETE_MVP_FOR_ITEM);
  const [setSalePriceMutation] = useMutation(CREATE_SALE_PRICE);

  // const saveOrUpdateProduct = () => {
  //   if (inventory) {
  //     updateInventory({
  //       variables: {
  //         vendorId,
  //         inventoryId: product.inventories[0].inventoryId,
  //         code: product.code,
  //         itemId: product.itemId,
  //         requestStock: inventory.value,
  //         requestAction: inventory.onChangeRequest === 2 ? 2 : 1
  //       }
  //     })
  //       .then(data => {
  //         openNotificationWithIcon({
  //           type: "success",
  //           title: "Success!",
  //           message: "Inventory is updated successfully"
  //         });
  //       })
  //       .catch(e => {
  //         console.log("Create/Update product error: ", e);
  //         openNotificationWithIcon({
  //           type: "error",
  //           title: "Error!",
  //           message: "Something went wrong!"
  //         });
  //       });
  //   }
  //   if (salePriceOnDelete.length !== 0) {
  //     deleteSalePriceMutation({
  //       variables: { priceIds: salePriceOnDelete }
  //     })
  //       .then(data => {
  //         openNotificationWithIcon({
  //           type: "success",
  //           title: "Success!",
  //           message: "Sale Price is updated successfully"
  //         });
  //       })
  //       .catch(e => {
  //         console.log("Create/Update product error: ", e);
  //         openNotificationWithIcon({
  //           type: "error",
  //           title: "Error!",
  //           message: "Something went wrong!"
  //         });
  //       });
  //   }
  //   if (setSalePrice) {
  //     console.log("setSalePrice ", setSalePrice);
  //   }
  //
  //   saveOrUpdateProductMutation({
  //     variables: { vendorId, ...product }
  //   })
  //     .then(() => {
  //       openNotificationWithIcon({
  //         type: "success",
  //         title: "Success!",
  //         message: "Product is updated successfully"
  //       });
  //     })
  //     .catch(e => {
  //       console.log("Create/Update product error: ", e);
  //       openNotificationWithIcon({
  //         type: "error",
  //         title: "Error!",
  //         message: "Something went wrong!"
  //       });
  //     });
  // };

  const confirm = e => {
    deleteItemMutation({
      variables: {
        vendorId,
        itemIds: [parseInt(product.itemId)]
      }
    }).then(() => {
      openNotificationWithIcon({
        type: "success",
        title: "Success!",
        message: "Product deleted successfully"
      });
      history.push({
        pathname: "/app/products"
      });
    });
  };

  const cancel = e => {
    console.log(e);
    message.error("Click on No");
  };

  const [form] = Form.useForm();

  useEffect(
    () => {
      if (product) {
        let priceObj =
          product.offerPrices &&
          product.offerPrices.find(obj => obj.default === "Y");
        let brand = product.multiValueProperties.find(
          obj => obj.propertyName === process.env.REACT_APP_BRAND
        );
        let upc =
          product.properties &&
          product.properties.find(
            obj => obj.propertyName === process.env.REACT_APP_UPC
          );
        let inStock = product.inventories && product.inventories[0].inStock;

        //console.log(priceObj);
        form.setFieldsValue({
          //Product Section
          title: product.title,
          shortDesc: product.shortDesc,
          longDesc: product.longDesc,
          origCategoryId:
            product.baseCategory && parseInt(product.baseCategory.categoryId),
          selectedBrandId: brand && parseInt(brand.propertyValueId),

          //Price and Inventory
          code: product.code,
          price: {
            listPrice: priceObj && priceObj.listPrice,
            price1: priceObj && priceObj.price1
          },
          inventory: {
            inStock
          },

          upc: upc && upc.propertyValue,
          weight: product.weight,
          weightType: product.weightType,

          //Availability section
          storeCode: product.storeCode,
          available: product.available,

          // Settings section
          itemType: product.itemType,
          taxable: product.taxable,
          shipType: product.shipType,
          inventory_units: "lb"
        });
      }
    },
    [product]
  );

  console.log("product ", product);
  const onOk = () => {
    form.submit();
  };
  const saveOrUpdateProduct = values => {
    // if (values.inventory) {
    //   if (product.inventories.length === 0) {
    //     // create inventory
    //     createInventory({
    //       variables: {
    //         vendorId,
    //         code: product.code,
    //         itemId: product.itemId
    //       }
    //     })
    //       .then(data => {
    //         openNotificationWithIcon({
    //           type: "success",
    //           title: "Success!",
    //           message: "Inventory is updated successfully"
    //         });
    //       })
    //       .catch(e => {
    //         console.log("Create/Update product error: ", e);
    //         openNotificationWithIcon({
    //           type: "error",
    //           title: "Error!",
    //           message: "Something went wrong!"
    //         });
    //       });
    //   } else {
    //     // update inventory
    //     updateInventory({
    //       variables: {
    //         vendorId,
    //         inventoryId: product.inventories[0].inventoryId,
    //         code: product.code,
    //         itemId: product.itemId,
    //         requestStock: values.inventory.requestStock,
    //         requestAction: values.inventory.requestAction === 2 ? 2 : 1
    //       }
    //     })
    //       .then(data => {
    //         openNotificationWithIcon({
    //           type: "success",
    //           title: "Success!",
    //           message: "Inventory is updated successfully"
    //         });
    //       })
    //       .catch(e => {
    //         console.log("Create/Update product error: ", e);
    //         openNotificationWithIcon({
    //           type: "error",
    //           title: "Error!",
    //           message: "Something went wrong!"
    //         });
    //       });
    //   }
    // }

    // if (values.price) {
    //   let priceObj =
    //     product.offerPrices &&
    //     product.offerPrices.find(obj => obj.default === "Y");
    //   updatePrice({
    //     variables: {
    //       vendorId,
    //       priceId: parseInt(priceObj.id),
    //       itemCode: product.code,
    //       listPrice: values.price.listPrice,
    //       price1: values.price.price1,
    //       itemId: parseInt(product.itemId),
    //       currencyId: parseInt(priceObj.currency.currencyId)
    //     }
    //   })
    //     .then(data => {
    //       openNotificationWithIcon({
    //         type: "success",
    //         title: "Success!",
    //         message: "Price is updated successfully"
    //       });
    //     })
    //     .catch(e => {
    //       console.log("Price Update error: ", e);
    //       openNotificationWithIcon({
    //         type: "error",
    //         title: "Error!",
    //         message: "Something went wrong!"
    //       });
    //     });
    // }
    // if (values.selectedBrandId) {
    //   deleteMVPForItem({
    //     variables: {
    //       itemId: product.itemId,
    //       propertyValueIds: [values.selectedBrandId]
    //     }
    //   })
    //     .then(() => {
    //       addMVPForItem({
    //         variables: {
    //           itemId: product.itemId,
    //           propertyValueIds: [values.selectedBrandId]
    //         }
    //       })
    //         .then(() => {
    //           openNotificationWithIcon({
    //             type: "success",
    //             title: "Success!",
    //             message: "A Brand changed successfully"
    //           });
    //         })
    //         .catch(error => console.error("Error while creating MVP: ", error));
    //     })
    //     .catch(error => console.error("Error while deleting MVP: ", error));
    // }
    //
    // saveOrUpdateProductMutation({
    //   variables: {
    //     vendorId,
    //     itemId: product.itemId,
    //     code: product.code,
    //     ...values
    //   }
    // })
    //   .then(() => {
    //     openNotificationWithIcon({
    //       type: "success",
    //       title: "Success!",
    //       message: "Product is updated successfully"
    //     });
    //   })
    //   .catch(e => {
    //     console.log("Create/Update product error: ", e);
    //     openNotificationWithIcon({
    //       type: "error",
    //       title: "Error!",
    //       message: "Something went wrong!"
    //     });
    //   });
    console.log("val ", values);
  };
  return (
    <>
      <Breadcrumb className="bread-crumb">
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Products</Breadcrumb.Item>
        <Breadcrumb.Item>Edit Product</Breadcrumb.Item>
      </Breadcrumb>
      <Row justify="space-between">
        <Col>
          <Typography>
            <h3>Edit Product</h3>
          </Typography>
        </Col>
        {/*<Col>*/}
        {/*  <Statistic title="Product Status" value={"New Items"} />*/}
        {/*</Col>*/}
      </Row>
      <div className="site-layout-content">
        <Row justify="space-between">
          <Col>
            <Form.Item>
              <Radio.Group
                defaultValue="simple"
                buttonStyle="solid"
                onChange={e => dispatch(switchEditMode(e.target.value))}
              >
                <Radio.Button value="simple">Simple</Radio.Button>
                <Radio.Button value="advanced" disabled>
                  Advanced
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col>
            <Popconfirm
              title="Are you sure delete this task?"
              placement="topRight"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger icon={<DeleteFilled />} />
            </Popconfirm>
          </Col>
        </Row>
        <Form
          form={form}
          layout="vertical"
          onFinish={values => saveOrUpdateProduct(values)}
          scrollToFirstError={true}
        >
          {viewMode === "advanced" ? (
            <Row>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Product Data" key="1">
                  <MainProductTabComponent
                    viewMode={viewMode}
                    itemCode={location.state.itemCode}
                  />
                </TabPane>
                <TabPane tab="Variations" key="2">
                  {/*<VariationsTabComponent />*/}
                </TabPane>
                <TabPane tab="Content Blocks" key="3">
                  <ContentBlockTabComponent />
                </TabPane>
                <TabPane tab="Video" key="4">
                  {/*<VideoTabComponent />*/}
                </TabPane>
                <TabPane tab="Properties" key="5">
                  <PropertiesTabComponent />
                </TabPane>
                <TabPane tab="Multi Valued Properties" key="6">
                  Content of Tab Pane 3
                </TabPane>
                <TabPane tab="Tags" key="7">
                  {/*<TagsTabComponent />*/}
                </TabPane>
                <TabPane tab="Inventory" key="8">
                  Content of Tab Pane 3
                </TabPane>
              </Tabs>
            </Row>
          ) : (
            <MainProductTabComponent
              //props={{ viewMode, itemCode: location.state.itemCode }}
              viewMode={viewMode}
              itemCode={location.state.itemCode}
            />
          )}
          <Divider />
          <Row justify="end">
            <Col>
              <Button htmlType="submit" type="primary" onClick={() => onOk}>
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
//EditProductComponent.whyDidYouRender = true;
