/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

//import "./wdyr";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import EntryPoint from "./EntryPoint";

import { BrowserRouter } from "react-router-dom";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "@apollo/react-hooks";
import { createHttpLink } from "apollo-link-http";
import { Provider } from "react-redux";
//import "./i18n";
import configureStore from "./redux/index.js";
import "antd/dist/antd.css";
import "./styles/css/style.css";
import * as serviceWorker from "./serviceWorker";

const link = createHttpLink({
  uri: process.env.REACT_APP_SERVER_PREVIEW + "graphql/",
  credentials: "include"
});

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

export const store = configureStore();

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Suspense fallback={<div>Loading</div>}>
        <Provider store={store}>
          <EntryPoint />
        </Provider>
      </Suspense>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.register();
