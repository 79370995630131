/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import React, { useState, useEffect, useRef, lazy } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Typography,
  Row,
  Col,
  Table,
  Button,
  message,
  Breadcrumb,
  Popconfirm,
  Switch,
  Input
} from "antd";
import Highlighter from "react-highlight-words";
import format from "dayjs";
import {
  DeleteFilled,
  PlusOutlined,
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined
} from "@ant-design/icons";
import {
  GET_INVENTORIES,
  DELETE_INVENTORY
} from "../../../../graphql/graphql_queries";
import { openNotificationWithIcon } from "../../../SharedComponents/notifications/notifications";
import ServerIsNotAvailableComponent from "../../../SharedComponents/ServerIsNotAvailableComponent";
import ChangeInventoryInputComponent from "./InventoryChildComponents/ChangeInventoryInputConponent";
import { shallowEqual, useSelector } from "react-redux";

const InventoryInfoModalComponent = lazy(() =>
  import("./InventoryInfoModalComponent")
);

export default function InventoryComponent() {
  const { vendorId } = useSelector(
    state => state.configProviderReducer.authState,
    shallowEqual
  );
  // states
  const [rowData, setRowData] = useState([{ inventoryId: "" }]);
  const [modalObject, setModalObject] = useState({
    visible: false,
    createNewInventory: false
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [search, setSearch] = useState({
    searchText: "",
    searchedColumn: ""
  });

  let searchInput = useRef();
  // mutations
  const [deleteInventoryMutation] = useMutation(DELETE_INVENTORY);

  const { loading, error, data } = useQuery(GET_INVENTORIES, {
    variables: { vendorId },
    // poll every 6 min
    pollInterval: process.env.REACT_APP_POLLINTERVAL
  });

  useEffect(
    () => {
      if (!error && !loading) {
        let tempData;
        tempData = data.getItems.filter(
          element => element.inventories.length > 0
        );
        tempData.forEach(element => (element.inventoryIds = []));
        tempData.forEach(inv => {
          inv.inventories.forEach(inventory => {
            inv.inventoryIds.push(inventory.inventoryId);
          });
        });

        setRowData(
          tempData.map((item, index) => {
            return {
              key: index,
              ...item,
              ...item.mcwItem,
              ...item.inventories[0]
            };
          })
        );
      }
    },
    [data, error, loading]
  );

  if (error) return <ServerIsNotAvailableComponent />;

  const callbackFunc = (val, arrData) => {
    let foundIndex = rowData.findIndex(
      element => element.itemId === arrData.updateInventory.itemId
    );

    let tempArr = [...rowData];

    tempArr[foundIndex].inStock = arrData.updateInventory.inStock;
    tempArr[foundIndex].modifyTime = arrData.updateInventory.modifyTime;
    console.log(tempArr[foundIndex]);
    tempArr.splice(foundIndex, 1, tempArr[foundIndex]);
    setRowData(tempArr);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: text =>
      search.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[search.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearch({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearch({ searchText: "" });
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "smallImage",
      key: "smallImage",
      render: text => {
        return (
          <img
            alt=""
            src={process.env.REACT_APP_SERVER_PREVIEW + "store" + text}
          />
        );
      }
    },
    {
      title: "Product Code",
      dataIndex: "code",
      key: "code",
      ...getColumnSearchProps("code")
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title")
    },
    {
      title: "Quantity in Stock",
      dataIndex: "inStock",
      key: "inStock"
    },
    {
      title: "MCW Code",
      dataIndex: "mcwItem",
      key: "mcwItem"
    },
    {
      title: "Increment / Decrement By",
      dataIndex: "increment",
      key: "increment",
      render: (text, record) => {
        return (
          <ChangeInventoryInputComponent
            callback={callbackFunc}
            props={rowData}
            record={record}
          />
        );
      }
    },
    {
      title: "Last Modified",
      dataIndex: "modifyTime",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        format(a.modifyTime).unix() - format(b.modifyTime).unix(),
      render: text => {
        return format(text).format("MMM DD HH:mm:ss");
      },
      key: "modifyTime"
    },
    {
      title: "Hidden",
      dataIndex: "hide",
      key: "hide",
      render: text => {
        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={text}
            disabled={true}
          />
        );
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Button
            aria-label={"Edit Inventory " + record.code}
            type="link"
            icon={<EditOutlined style={{ fontSize: "20px" }} />}
            onClick={() =>
              setModalObject({
                visible: true,
                record
              })
            }
          />
        );
      }
    }
  ];
  const onSelectChang = selectedRowKeys => setSelectedRowKeys(selectedRowKeys);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChang,
    renderCell: (checked, record, index, originNode) => {
      return originNode;
    }
  };

  const confirm = e => {
    let convertedSelectedRowKeys = selectedRowKeys.map(el => parseInt(el));
    deleteInventoryMutation({
      variables: {
        vendorId,
        inventoryIds: convertedSelectedRowKeys
      },
      refetchQueries: [
        {
          query: GET_INVENTORIES,
          variables: { vendorId }
        }
      ]
    }).then(() => {
      setSelectedRowKeys([]);
      openNotificationWithIcon({
        type: "success",
        title: "Success!",
        message: "Inventory deleted successfully"
      });
    });
  };

  const cancel = e => {
    console.log(e);
    message.error("Click on No");
  };
  const closeModal = () => setModalObject({ visible: false });

  return (
    <>
      <Breadcrumb className="bread-crumb">
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
      </Breadcrumb>
      <Typography>
        <h3>Inventory</h3>
      </Typography>
      <div className="site-layout-content">
        <Row justify="end">
          <Col>
            <Button
              type="primary"
              onClick={() =>
                setModalObject({
                  visible: true,
                  createNewInventory: true
                })
              }
              icon={<PlusOutlined />}
              style={{
                display: selectedRowKeys.length !== 0 ? "none" : "block"
              }}
            />
            <Popconfirm
              title="Are you sure delete this task?"
              placement="topRight"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                danger
                icon={<DeleteFilled />}
                style={{
                  display: selectedRowKeys.length !== 0 ? "block" : "none"
                }}
              />
            </Popconfirm>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <Table
              loading={loading}
              tableLayout={undefined}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={rowData}
              scroll={{ x: true }}
              rowKey="inventoryId"
            />
          </Col>
        </Row>
      </div>
      <InventoryInfoModalComponent
        callback={callbackFunc}
        props={modalObject}
        rowData={rowData}
        close={closeModal}
      />
    </>
  );
}
