import React from 'react'
import { Row, Col } from 'antd'

export default function FooterView() {
    const copyrightDiv = {
        textAlign: 'left',
        paddingLeft: 30,
        paddingTop: 20,
        marginBottom: 20,
        fontSize: 12,
        width: 'auto',
        float: 'left',
    }
    return (
        <>
            <Row>
                <Col>
                    <div style={copyrightDiv}>
                        <span style={{ color: '#C7C7C7' }}>
                            {new Date().getFullYear()} © Avetti.com Corp
                        </span>
                    </div>
                </Col>
            </Row>
        </>
    )
}
