/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import {
  SET_SALE_PRICE_ON_DELETE,
  UPDATE_INVENTORY_PRODUCT_INPUT_STATE,
  UPDATE_PRICE_PRODUCT_INPUT_STATE,
  SET_SALE_PRICE
} from "../types";

const initialState = { salePriceOnDelete: [] };

const mainReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_INVENTORY_PRODUCT_INPUT_STATE:
      if (state === payload) {
        return state;
      }
      return {
        ...state,
        inventory: payload
      };
    case UPDATE_PRICE_PRODUCT_INPUT_STATE:
      if (state === payload) {
        return state;
      }
      return {
        ...state,
        price: payload
      };
    case SET_SALE_PRICE_ON_DELETE:
      if (state === payload) {
        return state;
      }
      return {
        ...state,
        salePriceOnDelete: [...state.salePriceOnDelete, payload]
      };
    case SET_SALE_PRICE:
      if (state === payload) {
        return state;
      }
      return {
        ...state,
        setSalePrice: payload
      };
    default:
      return state;
  }
};

export default mainReducer;
