/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./auth/protected.route";
import Login from "./Login";
import App from "./App";
import { ConfigProvider } from "antd";
import { shallowEqual, useSelector } from "react-redux";

export default function EntryPoint() {
  const direction = useSelector(
    state => state.configProviderReducer.direction,
    shallowEqual
  );

  return (
    <ConfigProvider direction={direction}>
      <div className="entry-point">
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <ProtectedRoute path="/app/*" component={App} />
            <Route path="*" component={Login} />
          </Switch>
        </Router>
      </div>
    </ConfigProvider>
  );
}
//EntryPoint.whyDidYouRender = true;
