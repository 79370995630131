/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import {
  SWITCH_BETWEEN_LTR_TO_RTL,
  SET_LOGIN_STATE,
  SET_AUTH_STATE
} from "../types";

const initialState = {
  direction: "ltr",
  authState: {}
};
const mainReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SWITCH_BETWEEN_LTR_TO_RTL:
      return {
        ...state,
        direction: payload
      };
    case SET_LOGIN_STATE:
      return {
        ...state,
        loginState: payload
      };
    case SET_AUTH_STATE:
      return {
        ...state,
        authState: payload
      };
    default:
      return state;
  }
};

export default mainReducer;
