/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./auth";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setAuthorizationState } from "../redux/actions/configProviderActions";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [state, setState] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    auth.isAuthenticated(setState).then(res =>
      dispatch(
        setAuthorizationState({
          ...res,
          vendorId: res.vendors[0].vendorId
        })
      )
    );
  }, []);
  return (
    <Route
      {...rest}
      render={props => {
        if (state) {
          return <Component {...props} />;
        } else if (state === false) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        } else {
          return <span>Loading...</span>;
        }
      }}
    />
  );
};
