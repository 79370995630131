/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import { UPDATE_PRODUCT_INPUT_STATE } from "../types";
import produce from "immer";

const initialState = {};

const mainReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_PRODUCT_INPUT_STATE:
      if (state === payload) {
        return state;
      }
      return {
        ...state,
        product: payload
      };
    default:
      return state;
  }
};

export default mainReducer;
