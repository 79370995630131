/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import { Collapse, InputNumber, Form, Row, Col, Select, Input } from "antd";
import React, { useState, useEffect } from "react";
import SalePricingComponent from "./SalePricingComponent";
const { Panel } = Collapse;
const { Option } = Select;

export default function PricingInventoryComponent({
  code,
  offerPrices = [],
  inventories = [{ inStock: "0" }]
}) {
  const [priceAndInventoryState, setPriceAndInventoryState] = useState({
    mainPrice: { currency: { sign: "$" } },
    productInventory: 0
  });

  // const { inventory, price } = useSelector(
  //   state => state.pricingAndInventoryReducer,
  //   shallowEqual
  // );
  // const dispatch = useDispatch();

  useEffect(
    () => {
      if (offerPrices.length !== 0) {
        setPriceAndInventoryState({
          ...priceAndInventoryState,
          productInventory: (inventories[0] && inventories[0].inStock) || 0
        });
      }
    },
    [code]
  );

  return (
    <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
      <Panel header="Pricing and Inventory" key="1">
        <Row gutter={20}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item label="Product Code" name="code">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={4} xl={4}>
            <Form.Item
              label="List price / MSRP"
              name={["price", "listPrice"]}
              dependencies={["price", "price1"]}
              rules={[
                { required: true, message: "List Price is required" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value < getFieldValue(["price", "price1"])) {
                      return Promise.reject(
                        "List Price cannot be lower than Price"
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <InputNumber
                size="default"
                min={0}
                max={9999999}
                style={{ width: "100%" }}
                formatter={value =>
                  `${
                    priceAndInventoryState.mainPrice.currency.sign
                  } ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={4} xl={4}>
            <Form.Item
              label="Price"
              name={["price", "price1"]}
              dependencies={["price", "listPrice"]}
              rules={[
                { required: true, message: "Price is required" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value > getFieldValue(["price", "listPrice"])) {
                      return Promise.reject(
                        "Price cannot be higher than List Price"
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <InputNumber
                size="default"
                min={0}
                max={99999999}
                style={{ width: "100%" }}
                formatter={value =>
                  ` ${
                    priceAndInventoryState.mainPrice.currency.sign
                  } ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item
              label="Inventory"
              //rules={[{ required: true }]}
            >
              <Input.Group compact>
                <Form.Item noStyle name={["inventory", "inStock"]}>
                  <Input
                    style={{ width: "25%" }}
                    //value={priceAndInventoryState.productInventory}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item noStyle name={["inventory", "requestAction"]}>
                  <Select defaultValue={1} style={{ width: "50%" }}>
                    <Option value={1}>Increment By</Option>
                    <Option value={2}>Decrement By</Option>
                    <Option value={3}>Overwrite By</Option>
                  </Select>
                </Form.Item>
                <Form.Item noStyle name={["inventory", "requestStock"]}>
                  <InputNumber style={{ width: "25%" }} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24} sm={24} md={24} lg={7} xl={7}>
            <Form.Item
              label="Barcode"
              name="upc"
              //rules={[{ required: true, message: "Barcode is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={4} xl={4}>
            <Form.Item label="Product Weight">
              <Input.Group compact>
                <Form.Item noStyle name="weight">
                  <InputNumber min={0} max={999999} style={{ width: "50%" }} />
                </Form.Item>
                <Form.Item noStyle name="weightType">
                  <Select style={{ width: "50%" }}>
                    <Option value={1}>lb</Option>
                    <Option value={2}>kg</Option>
                    <Option value={3}>gr</Option>
                    <Option value={4}>once</Option>
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>{/*<SalePricingComponent />*/}</Col>
        </Row>
      </Panel>
    </Collapse>
  );
}
