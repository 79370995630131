/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  Tabs,
  Button,
  Form,
  Input,
  Row,
  Col,
  Divider,
  Select
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  CREATE_ITEM,
  GET_CATEGORY_NAMES
} from "../../../../graphql/graphql_queries";
import { shallowEqual, useSelector } from "react-redux";

const { TabPane } = Tabs;
const { Option } = Select;

export default function CreateNewProductModalComponent({
  props,
  close,
  existingItemCode = []
}) {
  const { vendorId } = useSelector(
    state => state.configProviderReducer.authState,
    shallowEqual
  );
  const { loading, error, data } = useQuery(GET_CATEGORY_NAMES, {
    variables: { vendorId },
    pollInterval: process.env.REACT_APP_POLLINTERVAL,
    skip: typeof vendorId === "undefined"
  });
  const [rowData, setRowData] = useState([]);
  const [createProductState, setCreateProductState] = useState({
    code: "",
    title: "",
    origCategoryId: ""
  });
  const [createProductStateWay, setCreateProductStateWay] = useState("2");

  const [createProductMutation] = useMutation(CREATE_ITEM);
  const history = useHistory();
  const [form] = Form.useForm();
  useEffect(
    () => {
      if (data && !error && !loading) {
        let arrayOfElements = [];
        let tempArr = data.getCategories.map(e => {
          return {
            title: e.name,
            value: parseInt(e.categoryId)
          };
        });
        tempArr.forEach((obj, index) =>
          arrayOfElements.push(
            <Option key={index} value={obj.value}>
              {obj.title}
            </Option>
          )
        );
        setRowData([...arrayOfElements]);
      }
    },
    [data]
  );
  const createItemProcess = () => {
    createProductMutation({ variables: { ...createProductState } })
      .then(({ data }) => {
        console.log(data.createItem.code);
        history.push({
          pathname: "/app/edit-product",
          state: { itemCode: data.createItem.code }
        });
      })
      .catch(error => console.log("Error while creating a product ", error));
  };

  //console.log(createProductState);

  // reset form fields when modal is form, closed
  const useResetFormOnCloseModal = ({ form, visible }) => {
    const prevVisibleRef = useRef();
    useEffect(
      () => {
        prevVisibleRef.current = visible;
      },
      [visible]
    );
    const prevVisible = prevVisibleRef.current;
    useEffect(
      () => {
        if (!visible && prevVisible) {
          form.resetFields();
        }
      },
      [visible]
    );
  };

  useResetFormOnCloseModal({
    form,
    visible: props.visible
  });
  const onOk = () => {
    form.submit();
  };

  return (
    <Modal
      title="Add a New Product"
      style={{ top: 20 }}
      visible={props.visible}
      onCancel={() => close()}
      footer={[
        <Button key="back" onClick={() => close()}>
          Return
        </Button>,
        createProductStateWay === "1" ? (
          <Button key="submit" type="primary" loading={loading}>
            Go To Marketplace
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => onOk()}
          >
            Add New Product
          </Button>
        )
      ]}
    >
      <Tabs
        defaultActiveKey={createProductStateWay}
        type="card"
        onChange={e => setCreateProductStateWay(e)}
      >
        <TabPane tab="Import Product" key="1" disabled>
          <Row gutter={20}>
            <Col>
              Hey! Search within our catalog first for supplying/selling
              products that may already be listed.
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Create Product" key="2">
          <Form
            validateMessages={{
              default: ""
            }}
            form={form}
            layout="vertical"
            onFinish={() => createItemProcess()}
            onValuesChange={(changedValues, allValues) => {
              ///console.log(changedValues);
              setCreateProductState({ ...allValues, vendorId });
            }}
          >
            <Row gutter={20}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Product Code"
                  name="code"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input Product Code!"
                    },
                    {
                      max: 50,
                      message: "You cannot use more that 50 characters"
                    },
                    {
                      min: 3,
                      message: "You have to use more than 3 characters"
                    },
                    {
                      pattern: new RegExp("^[\\w\\d_]+$"),
                      message:
                        "Only letters, numbers and underscores are allowed"
                    },
                    () => ({
                      validator(rule, value) {
                        if (
                          existingItemCode.some(
                            obj =>
                              obj.itemCode.toUpperCase() === value.toUpperCase()
                          )
                        ) {
                          return Promise.reject("Item code already exists!");
                        }
                        return Promise.resolve();
                      }
                    })
                  ]}
                >
                  <Input
                    value={createProductState.code}
                    allowClear
                    size="default"
                    placeholder="Product Code"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Category"
                  name="origCategoryId"
                  rules={[
                    {
                      required: true,
                      message: "Please input Category!"
                    }
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Category"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {rowData}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input Product Title!"
                    }
                  ]}
                >
                  <Input allowClear size="default" placeholder="Title" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </TabPane>
      </Tabs>
    </Modal>
  );
}

//CreateNewProductModalComponent.whyDidYouRender = true;
