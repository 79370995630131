/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */
export const SWITCH_BETWEEN_SIMPLE_AND_ADVANCED_VIEW =
  "SWITCH_BETWEEN_SIMPLE_AND_ADVANCED_VIEW";
export const SWITCH_BETWEEN_LTR_TO_RTL = "SWITCH_BETWEEN_LTR_TO_RTL";
export const SET_LOGIN_STATE = "SET_LOGIN_STATE";
export const UPDATE_PRODUCT_INPUT_STATE = "UPDATE_PRODUCT_INPUT_STATE";
export const UPDATE_INVENTORY_PRODUCT_INPUT_STATE =
  "UPDATE_INVENTORY_PRODUCT_INPUT_STATE";
export const SET_AUTH_STATE = "SET_AUTH_STATE";
export const UPDATE_PRICE_PRODUCT_INPUT_STATE =
  "UPDATE_PRICE_PRODUCT_INPUT_STATE";
export const SET_SALE_PRICE_ON_DELETE = "SET_SALE_PRICE_ON_DELETE";
export const SET_SALE_PRICE = "SET_SALE_PRICE";
