/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_INVENTORY } from "../../../../../graphql/graphql_queries";
import { openNotificationWithIcon } from "../../../../SharedComponents/notifications/notifications";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { shallowEqual, useSelector } from "react-redux";

export default function ChangeInventoryInputComponent({
  props,
  record,
  callback
}) {
  const { vendorId } = useSelector(
    state => state.configProviderReducer.authState,
    shallowEqual
  );
  const [number, setNumber] = useState("");
  const [updateInventoryMutation] = useMutation(UPDATE_INVENTORY);

  let updateInventory = event => {
    updateInventoryMutation({
      variables: {
        vendorId,
        inventoryId: record.inventoryId,
        code: record.code,
        itemId: record.itemId,
        requestStock: parseInt(event.target.value),
        // 1 - increment/decrement
        // 2 - overwrite
        requestAction: 1
      }
    }).then(({ data }) => {
      let tempObj = { ...props.record };
      tempObj.inStock = data.updateInventory.inStock;
      tempObj.modifyTime = data.updateInventory.modifyTime;
      let tempArr = [tempObj];
      //console.log('tempObj: ', tempObj);
      callback(tempArr, data);

      openNotificationWithIcon({
        type: "success",
        title: "Success!",
        message: "Inventory updated successfully"
      });
      setNumber("");
    });
  };

  return (
    <Row justify="space-between" align="middle" gutter={5}>
      <Col
        xs={number === "" ? 24 : 24}
        sm={number === "" ? 24 : 24}
        md={number === "" ? 24 : 24}
        lg={number === "" ? 14 : 14}
        xl={number === "" ? 14 : 14}
      >
        <Input
          aria-labelledby={
            "Increment / Decrement Inventory of product code: " + record.code
          }
          type="number"
          onChange={e => setNumber(e.target.value)}
          value={number}
          placeholder="0"
          onPressEnter={event => updateInventory(event)}
        />
      </Col>
      <Col
        style={{ display: number === "" ? "none" : "block" }}
        xs={24}
        sm={24}
        md={24}
        lg={5}
        xl={5}
      >
        <Button onClick={() => setNumber("")} icon={<CloseOutlined />} />
      </Col>
      <Col
        style={{ display: number === "" ? "none" : "block" }}
        xs={24}
        sm={24}
        md={24}
        lg={5}
        xl={5}
      >
        <Button
          type="primary"
          icon={<CheckOutlined />}
          onClick={event => updateInventory(event)}
        />
      </Col>
    </Row>
  );
}
