/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Collapse, Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";
import productStateReducer from "../../../../../../redux/reducers/productStateReducer";
import { openNotificationWithIcon } from "../../../../../SharedComponents/notifications/notifications";

const { Panel } = Collapse;
export default function ImagesSectionComponent({
  mainImage = "",
  additionalImages = ""
}) {
  const { vendorId, securityToken } = useSelector(
    state => state.configProviderReducer.authState,
    shallowEqual
  );
  const { product } = useSelector(
    state => state.productStateReducer,
    shallowEqual
  );
  const [imgState, setImgState] = useState({
    fileList: [],
    fileListOfAdditionalImg: []
  });

  const config = {
    headers: {
      "access-control-allow-headers":
        "DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type",
      "content-encoding": "gzip",
      "front-end-https": "on",
      server: "nginx",
      "strict-transport-security": "max-age=31536000; includeSubdomains;",
      vary: "Accept-Encoding",
      "x-content-type-options": "nosniff",
      "x-frame-options": "SAMEORIGIN",
      "x-xss-protection": "1; mode=block",
      "content-type": "multipart/form-data;"
    }
  };

  const removeFile = (file, mainImage) => {
    // mainImage = 0
    // Additional = 1
    //console.log("asdfasdf ", file);
    const data = new FormData();
    data.append("securitytoken", securityToken);
    axios({
      url:
        process.env.REACT_APP_SERVER_PREVIEW +
        "ajaxuploaditemImg.admin?ajax=true&vid=" +
        vendorId +
        "&code=" +
        product.code +
        "&seq=" +
        mainImage +
        "&filename=" +
        file.name +
        "&delete=true",
      method: "post",
      responseType: "json",
      withCredentials: true,
      data,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      config
    });
  };

  const customUploadImage = (options, mainImage) => {
    // mainImage = 0
    // Additional = 1
    //console.log(options, mainImage);
    const data = new FormData();
    data.append("file", options.file);
    data.append("securitytoken", securityToken);
    axios({
      url:
        "https://c11ca1.avetti.ca/preview/ajaxuploaditemImg.admin?ajax=true&vid=" +
        vendorId +
        "&code=" +
        product.code +
        "&seq=" +
        mainImage,
      method: "post",
      responseType: "json",
      withCredentials: true,
      data,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      config
    })
      .then(({ data }) => {
        if (data.result.success) {
          setImgState(
            mainImage === "0"
              ? {
                  ...imgState,
                  fileList: [
                    ...imgState.fileList,
                    {
                      uid: uuidv4(),
                      status: "done",
                      name: data.result.result.name,
                      url:
                        process.env.REACT_APP_SERVER_PREVIEW +
                        "store" +
                        data.result.result.storePath
                    }
                  ]
                }
              : {
                  ...imgState,
                  fileListOfAdditionalImg: [
                    ...imgState.fileListOfAdditionalImg,
                    {
                      uid: uuidv4(),
                      status: "done",
                      name: data.result.result.name,
                      url:
                        process.env.REACT_APP_SERVER_PREVIEW +
                        "store" +
                        data.result.result.storePath
                    }
                  ]
                }
          );
        } else if (!data.result.success) {
          openNotificationWithIcon({
            type: "error",
            title: "Error!",
            message: data.result.message
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  //console.log("imgState ", imgState);
  useEffect(
    () => {
      //console.log("dafsfd");
      if (mainImage || additionalImages) {
        let additionalImagesArr =
          (additionalImages &&
            additionalImages.split(",").map(image => ({
              uid: uuidv4(),
              status: "done",
              url:
                process.env.REACT_APP_SERVER_PREVIEW +
                "store/" +
                vendorId +
                "/assets/items/images/" +
                image,
              name: image.substring(image.lastIndexOf("/") + 1, image.length)
            }))) ||
          [];

        let noimageDetector = mainImage.substring(
          mainImage.lastIndexOf("/") + 1,
          mainImage.length
        );
        setImgState({
          ///...imgState,
          fileList: [
            ...imgState.fileList,
            {
              uid: uuidv4(),
              status: "done",
              url: process.env.REACT_APP_SERVER_PREVIEW + "store" + mainImage,
              name: mainImage.substring(
                mainImage.lastIndexOf("/") + 1,
                mainImage.length
              )
            }
          ],
          fileListOfAdditionalImg: imgState.fileListOfAdditionalImg.concat(
            additionalImagesArr
          )
        });
      }
    },
    [mainImage, additionalImages]
  );

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setImgState({
      ...imgState,
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };

  return (
    <>
      <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
        <Panel header="Image" key="1">
          <div className="primary-image">
            <Upload
              customRequest={options => customUploadImage(options, "0")}
              onChange={info => {
                //console.log(info.file.status);
                if (info.file.status !== "uploading") {
                  //console.log("Status", info.file.status);
                  setImgState({ ...imgState, fileList: info.fileList });
                } else if (info.file.status === "removed") {
                  //console.log("Status", info.file.status);
                }
              }}
              onRemove={file => removeFile(file, 0)}
              listType="picture-card"
              fileList={imgState.fileList}
              onPreview={handlePreview}
              className="avatar-uploader"
            >
              {imgState.fileList && imgState.fileList.length >= 1
                ? null
                : uploadButton}
            </Upload>
            <Modal
              visible={imgState.previewVisible}
              title={imgState.previewTitle}
              footer={null}
              onCancel={() =>
                setImgState({
                  ...imgState,
                  previewVisible: false
                })
              }
            >
              <img
                alt="example"
                style={{ width: "100%" }}
                src={imgState.previewImage}
              />
            </Modal>
          </div>
        </Panel>
        <Panel header="Additional Images" key="2">
          <div className="secondary-images">
            <Upload
              //multiple={true}
              customRequest={options => customUploadImage(options, "1")}
              onRemove={file => removeFile(file, 1)}
              listType="picture-card"
              fileList={imgState.fileListOfAdditionalImg}
              onPreview={handlePreview}
              onChange={({ fileList }) =>
                setImgState({
                  ...imgState,
                  fileListOfAdditionalImg: fileList
                })
              }
            >
              {uploadButton}
            </Upload>
          </div>
        </Panel>
      </Collapse>
    </>
  );
}
