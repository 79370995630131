/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import React, { Suspense, lazy } from "react";
import { ConfigProvider, Layout, Spin } from "antd";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FooterView from "./components/FooterView";
import HeaderComp from "./components/HeaderComp";
import MenuComponent from "./components/MenuComponent";
import InventoryComponent from "./components/Menu/Catalog/Inventory/InventoryComponent";
import EditProductComponent from "./components/Menu/Catalog/Products/EditProduct/EditProductComponent";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ProductListComponent from "./components/Menu/Catalog/Products/ProductListComponent";
import { setLoginState } from "./redux/actions/configProviderActions";
import MarketplaceComponent from "./components/Menu/Marketplace/MarketplaceComponent";

// lazy loading
const DashboardComponent = lazy(() =>
  import("./components/Menu/Dashboard/DashboardComponent")
);
const PriceComponent = lazy(() =>
  import("./components/Menu/Catalog/Price/PriceComponent")
);
const ManageOrdersComponent = lazy(() =>
  import("./components/Menu/Orders/ManageOrders/ManageOrdersComponent")
);
const CategoryComponent = lazy(() =>
  import("./components/Menu/Catalog/Category/CategoryComponent")
);
const { Header, Footer, Content } = Layout;

export default function App(props) {
  const dispatch = useDispatch();

  // TODO: that dispatch cuase of multiple rerenders. Have to check why
  dispatch(setLoginState(props));
  const direction = useSelector(
    state => state.configProviderReducer.direction,
    shallowEqual
  );
  const headerStyle = { background: "#03A9F4" };
  const layoutStyle = { minHeight: "100vh" };

  return (
    <ConfigProvider direction={direction}>
      <Router>
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <HeaderComp {...props} />
          </Header>
          <MenuComponent />
          <Content>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route path="/app/dashboard">
                  <Suspense fallback={<Spin size="large" />}>
                    <DashboardComponent />
                  </Suspense>
                </Route>
                <Route path="/app/prices">
                  <Suspense fallback={<Spin size="large" />}>
                    <PriceComponent />
                  </Suspense>
                </Route>
                <Route path="/app/inventory">
                  <InventoryComponent />
                </Route>
                <Route path="/app/category">
                  <Suspense fallback={<Spin size="large" />}>
                    <CategoryComponent />
                  </Suspense>
                </Route>
                <Route path="/app/manage-orders">
                  <Suspense fallback={<Spin size="large" />}>
                    <ManageOrdersComponent />
                  </Suspense>
                </Route>
                <Route path="/app/products">
                  <ProductListComponent />
                </Route>
                <Route
                  path="/app/edit-product"
                  render={props => <EditProductComponent {...props} />}
                />
                <Route
                  path="/app/create-product"
                  render={props => <EditProductComponent {...props} />}
                />
                <Route path="/app/marketplace">
                  <MarketplaceComponent />
                </Route>
              </Switch>
            </Suspense>
          </Content>
          <Footer>
            <FooterView />
          </Footer>
        </Layout>
      </Router>
    </ConfigProvider>
  );
}

// App.whyDidYouRender = true;
