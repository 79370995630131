/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
  Typography,
  Skeleton
} from "antd";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  DELETE_ITEM,
  GET_LIST_OF_ITEMS
} from "../../../../graphql/graphql_queries";
import format from "dayjs";
import {
  DeleteFilled,
  EditOutlined,
  PlusOutlined,
  SearchOutlined
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import CreateNewProductModalComponent from "./CreateNewProductModalComponent";
import noimage from "../../../../images/noimage.gif";
import { openNotificationWithIcon } from "../../../SharedComponents/notifications/notifications";
const { Text } = Typography;
const { Option } = Select;

export default function ProductListComponent() {
  const { vendorId } = useSelector(
    state => state.configProviderReducer.authState,
    shallowEqual
  );
  const [modalObject, setModalObject] = useState({
    visible: false
  });
  // const [rowData, setRowData] = useState([
  //   {
  //     //itemId: "",
  //     offerPrices: []
  //   }
  // ]);
  const [listOfItemCodeObject, setListOfItemCodeObject] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [search, setSearch] = useState({
    searchText: "",
    searchedColumn: ""
  });
  const [productStatuses, setProductStatuses] = useState();

  let searchInput = useRef();

  const { loading, error, data } = useQuery(GET_LIST_OF_ITEMS, {
    variables: { vendorId },
    // poll every 6 min
    pollInterval: process.env.REACT_APP_POLLINTERVAL,
    skip: typeof vendorId === "undefined"
  });

  const setModalObjectCallback = useCallback(
    () => setModalObject({ visible: false }),
    [modalObject]
  );

  console.log(data);
  const [deleteItemMutation] = useMutation(DELETE_ITEM);
  useEffect(
    () => {
      if (data && !error && !loading) {
        //setRowData(data.getItems);
        setListOfItemCodeObject(
          data.getItems.map(e => {
            return {
              itemCode: e.code
            };
          })
        );
        let arrayOfElements = [];
        let tempArr = data.getStoreCodes.map(e => {
          return {
            title: e.codeName,
            value: e.code
          };
        });
        tempArr.forEach((obj, index) =>
          arrayOfElements.push(
            <Option key={index} value={obj.value}>
              {obj.title}
            </Option>
          )
        );
        setProductStatuses([...arrayOfElements]);
      }
    },
    [data]
  );

  //if (error) return <ServerIsNotAvailableComponent />;
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: text =>
      search.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[search.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearch({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearch({ searchText: "" });
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "smallImage",
      key: "smallImage",
      render: text => {
        return (
          <img
            alt=""
            src={
              text !== ""
                ? process.env.REACT_APP_SERVER_PREVIEW + "store" + text
                : noimage
            }
          />
        );
      }
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title"),
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        format(a.modifyTime).unix() - format(b.modifyTime).unix(),
      render: (text, record) => {
        return (
          <>
            <Text>{text}</Text>
            <br />
            <Text code>
              Modified:
              {format(record.modifyTime).format("MMM DD HH:mm:ss")}
            </Text>
          </>
        );
      }
    },
    {
      title: "Product Code",
      dataIndex: "code",
      key: "code",
      ...getColumnSearchProps("code")
    },
    {
      title: "Quantity",
      dataIndex: "inStock",
      key: "inStock",
      render: (text, record) => {
        if (record.inventories && record.inventories.length !== 0) {
          return record.inventories[0].inStock;
        } else {
          return 0;
        }
      }
    },
    {
      title: "List Price",
      dataIndex: "mcwItem",
      key: "mcwItem",
      width: "10%",
      render: (text, record) => {
        return (
          record.offerPrices.length !== 0 &&
          record.offerPrices.find(obj => obj.default === "Y").currency.sign +
            " " +
            record.offerPrices
              .find(obj => obj.default === "Y")
              .listPrice.toFixed(2)
        );
      }
    },
    {
      title: "Price",
      dataIndex: "increment",
      key: "increment",
      width: "10%",
      render: (text, record) => {
        return (
          record.offerPrices.length !== 0 &&
          record.offerPrices.find(obj => obj.default === "Y").currency.sign +
            " " +
            record.offerPrices
              .find(obj => obj.default === "Y")
              .price1.toFixed(2)
        );
      }
    },
    {
      title: "Product Status",
      dataIndex: "storeCode",
      key: "storeCode",
      render: (text, record) => (
        <Select value={record.storeCode && record.storeCode.codeName}>
          {productStatuses}
        </Select>
      )
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Link
            to={{
              pathname: "/app/edit-product",
              state: {
                itemCode: record.code
              }
            }}
          >
            <EditOutlined style={{ fontSize: "20px" }} />
          </Link>
        );
      }
    }
  ];
  const onSelectChang = selectedRowKeys => setSelectedRowKeys(selectedRowKeys);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChang,
    renderCell: (checked, record, index, originNode) => {
      return originNode;
    }
  };

  const confirm = e => {
    let convertedSelectedRowKeys = selectedRowKeys.map(el => parseInt(el));
    deleteItemMutation({
      variables: {
        vendorId,
        itemIds: convertedSelectedRowKeys
      },
      refetchQueries: [
        {
          query: GET_LIST_OF_ITEMS,
          variables: { vendorId }
        }
      ]
    }).then(() => {
      setSelectedRowKeys([]);
      openNotificationWithIcon({
        type: "success",
        title: "Success!",
        message: "Product deleted successfully"
      });
    });
  };

  const cancel = e => {
    console.log(e);
    message.error("Click on No");
  };

  return (
    <>
      <Breadcrumb className="bread-crumb">
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>My Products</Breadcrumb.Item>
      </Breadcrumb>
      <Typography>
        <h3>My Products</h3>
      </Typography>
      <div className="site-layout-content">
        <Row justify="end">
          <Col>
            <Button
              type="primary"
              onClick={e =>
                setModalObject({
                  visible: true,
                  createNewProduct: true
                })
              }
              icon={<PlusOutlined />}
              style={{
                display: selectedRowKeys.length !== 0 ? "none" : "block"
              }}
            />

            <Popconfirm
              title="Are you sure delete this task?"
              placement="topRight"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                danger
                icon={<DeleteFilled />}
                style={{
                  display: selectedRowKeys.length !== 0 ? "block" : "none"
                }}
              />
            </Popconfirm>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            {loading ? (
              <Skeleton active />
            ) : (
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data && data.getItems}
                rowKey="itemId"
                loading={loading}
                scroll={{ x: true }}
                tableLayout={undefined}
              />
            )}
          </Col>
          <CreateNewProductModalComponent
            existingItemCode={listOfItemCodeObject}
            props={modalObject}
            close={setModalObjectCallback}
          />
        </Row>
      </div>
    </>
  );
}
//ProductListComponent.whyDidYouRender = true;
