/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import React, { useEffect, useState } from "react";
import { Collapse, Input, Select, Row, Col, Form } from "antd";

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

export default function ProductSectionComponent({
  listOfBrands = { propertyValues: [] },
  listOfCategories
}) {
  const [rowData, setRowData] = useState([]);
  const brandsArr = [];
  listOfBrands.propertyValues.map((obj, index) => {
    return brandsArr.push(
      <Option key={index} value={parseInt(obj.propertyValueId)}>
        {obj.propertyValue}
      </Option>
    );
  });

  useEffect(
    () => {
      let arrayOfElements = [];
      let tempArr = listOfCategories.map(e => {
        return {
          title: e.name,
          value: e.categoryId
        };
      });
      tempArr.forEach((obj, index) =>
        arrayOfElements.push(
          <Option key={index} value={parseInt(obj.value)}>
            {obj.title}
          </Option>
        )
      );
      setRowData([...arrayOfElements]);
    },
    [listOfCategories]
  );

  return (
    <>
      <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
        <Panel header="Product" key="1">
          <Input.Group size="default">
            <Row gutter={20}>
              <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: "Title is empty" }]}
                >
                  <Input allowClear size="default" placeholder="Title" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                <Form.Item
                  label="Top Menu Category"
                  rules={[{ required: true }]}
                  name="origCategoryId"
                >
                  <Select
                    showSearch
                    placeholder="Category"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {rowData}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                <Form.Item label="Brand" name="selectedBrandId">
                  <Select
                    size="default"
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select brand"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {brandsArr}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label="Short Description" name="shortDesc">
                  <TextArea
                    allowClear
                    autoSize={{
                      minRows: 2,
                      maxRows: 6
                    }}
                    placeholder="Short Description"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label="Description" name="longDesc">
                  <TextArea placeholder="Description Rich Editor" rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
        </Panel>
      </Collapse>
    </>
  );
}
