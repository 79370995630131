/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import React from "react";

import { Row, Col, Tooltip, Button, Space } from "antd";
import { useSelector, shallowEqual } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import auth from "../auth/auth";
import { Typography } from "antd";
import logo from "../images/avettiLogo.png";

const { Title } = Typography;
export default function HeaderComp() {
  const logoStyle = {
    fontSize: 18,
    color: "white"
  };
  const logOutStyle = {
    color: "white"
  };

  const loginState = useSelector(
    state => state.configProviderReducer.loginState,
    shallowEqual
  );

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Title level={2} style={logoStyle}>
          <img
            alt="Logo"
            src={logo}
            style={{
              width: "10%"
            }}
          />
          <span style={{ marginLeft: "1rem" }}>Supplier Catalog</span>
        </Title>
      </Col>
      <Col>
        <Tooltip placement="bottom" title={"Logout"}>
          <Button
            type="link"
            style={logOutStyle}
            icon={<LogoutOutlined />}
            rel="noopener noreferrer"
            onClick={() => {
              console.log("click");
              auth.logout(() => {
                loginState.history.push("/");
              });
            }}
          />
        </Tooltip>
      </Col>
    </Row>
  );
}
