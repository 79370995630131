/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */
import React, { useEffect, useState } from "react";
import { Collapse, Row, Col, Select, Form } from "antd";
import { useMutation } from "@apollo/react-hooks";
import {
  DELETE_MVP_FOR_ITEM,
  ADD_MVP_FOR_ITEM
} from "../../../../../../graphql/graphql_queries";
import { openNotificationWithIcon } from "../../../../../SharedComponents/notifications/notifications";

const { Panel } = Collapse;
const { Option } = Select;

export default function CategorizationComponent({
  listOfCollections = { propertyValues: [] },
  listOfCategories = { propertyValues: [] },
  listOfTags = { propertyValues: [] },
  selectedCollections,
  selectedCategories,
  selectedTags,
  itemId
}) {
  const [selectedCollectionsArr, setSelectedCollectionsArr] = useState([]);
  const [selectedCategoriesArr, setSelectedCategoriesArr] = useState([]);
  const [selectedTagsArr, setSelectedTagsArr] = useState([]);

  const [addMVPForItem] = useMutation(ADD_MVP_FOR_ITEM);
  const [deleteMVPForItem] = useMutation(DELETE_MVP_FOR_ITEM);

  //listOf**** - all available options for that particular field
  const COLLECTION = "Collection";
  const CATEGORY = "Category";
  const TAG = "Tag";

  const collectionArr = [];
  const categoryArr = [];
  const tagArr = [];

  useEffect(
    () => {
      setSelectedCollectionsArr(
        selectedCollections.map(obj => obj.propertyValueId)
      );
      setSelectedCategoriesArr(
        selectedCategories.map(obj => obj.propertyValueId)
      );
      setSelectedTagsArr(selectedTags.map(obj => obj.propertyValueId));
    },
    [selectedCollections, selectedCategories, selectedTags]
  );

  listOfCollections.propertyValues.map(obj => {
    return collectionArr.push(
      <Option key={obj.propertyValueId}>{obj.propertyValue}</Option>
    );
  });
  listOfCategories.propertyValues.map(obj => {
    return categoryArr.push(
      <Option key={obj.propertyValueId}>{obj.propertyValue}</Option>
    );
  });
  listOfTags.propertyValues.map(obj => {
    tagArr.push(<Option key={obj.propertyValueId}>{obj.propertyValue}</Option>);
  });

  const addMVP = (value, attr) => {
    addMVPForItem({
      variables: { itemId, propertyValueIds: [value] }
    })
      .then(() => {
        openNotificationWithIcon({
          type: "success",
          title: "Success!",
          message: "A " + attr + " added successfully"
        });
      })
      .catch(error => console.error("Error while deleting MVP: ", error));
    if (attr === COLLECTION) {
      setSelectedCollectionsArr([...selectedCollectionsArr, value]);
    } else if (attr === CATEGORY) {
      setSelectedCategoriesArr([...selectedCategoriesArr, value]);
    } else if (attr === TAG) {
      setSelectedTagsArr([...selectedTagsArr, value]);
    } else {
      return null;
    }
  };

  const removeMVP = (value, attr) => {
    deleteMVPForItem({
      variables: { itemId, propertyValueIds: [value] }
    })
      .then(() => {
        openNotificationWithIcon({
          type: "success",
          title: "Success!",
          message: "A " + attr + " removed successfully"
        });
      })
      .catch(error => console.error("Error while deleting MVP: ", error));
    if (attr === COLLECTION) {
      setSelectedCollectionsArr(
        selectedCollectionsArr.filter(item => item !== value)
      );
    } else if (attr === CATEGORY) {
      setSelectedCategoriesArr(
        selectedCategoriesArr.filter(item => item !== value)
      );
    } else if (attr === TAG) {
      setSelectedTagsArr(selectedTagsArr.filter(item => item !== value));
    } else {
      return null;
    }
  };

  //console.log("selectedCategories ", selectedCategories);

  return (
    <>
      <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
        <Panel header="Categorization" key="1">
          <Row>
            <Col span={24}>
              <Form.Item label="Collection">
                <Select
                  style={{ width: "100%" }}
                  mode="tags"
                  placeholder="Collections"
                  value={selectedCollectionsArr}
                  onSelect={value => addMVP(value, COLLECTION)}
                  onDeselect={value => removeMVP(value, COLLECTION)}
                >
                  {collectionArr}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Category">
                <Select
                  style={{ width: "100%" }}
                  mode="tags"
                  placeholder="Categories"
                  value={selectedCategoriesArr}
                  onSelect={value => addMVP(value, CATEGORY)}
                  onDeselect={value => removeMVP(value, CATEGORY)}
                >
                  {categoryArr}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Tag">
                <Select
                  style={{ width: "100%" }}
                  mode="tags"
                  placeholder="Tags"
                  value={selectedTagsArr}
                  onSelect={value => addMVP(value, TAG)}
                  onDeselect={value => removeMVP(value, TAG)}
                >
                  {tagArr}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
}
//CategorizationComponent.whyDidYouRender = true;
