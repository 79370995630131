/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */
import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Spin, Select, Card } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import auth from "./auth/auth";
import { openNotificationWithIcon } from "./components/SharedComponents/notifications/notifications";
import logo from "./images/avettiLogo.png";
import {
  setLoginState,
  switchDirection
} from "./redux/actions/configProviderActions";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Login(props) {
  const { t, i18n } = useTranslation();

  const handleChangeLanguage = lang => {
    if (lang === "ar") {
      i18n.changeLanguage(lang);
      dispatch(switchDirection("rtl"));
    } else {
      i18n.changeLanguage(lang);
      dispatch(switchDirection("ltr"));
    }
  };
  //console.log('PROPS', props);
  const dispatch = useDispatch();
  dispatch(setLoginState(props));

  const [loading, setLoading] = useState(false);
  const { Option } = Select;

  const handleSubmit = values => {
    setLoading(true);
    // real login
    let bodyData = new FormData();
    bodyData.append("ajaxrequest", true);
    bodyData.append("j_username", values.j_username);
    bodyData.append("j_password", values.j_password);
    bodyData.append("_acegi_security_remember_me", "on");
    axios({
      url:
        process.env.REACT_APP_SERVER_PREVIEW + `simplemerce_admin_login.admin`,
      method: "post",
      responseType: "json",
      withCredentials: true,
      data: bodyData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    })
      .then(({ status, data, statusText }) => {
        console.log(data);
        console.log(status);
        setLoading(false);
        if (data.success === true) {
          auth.login(() => {
            props.history.push("/app/products");
          });
        } else {
          openNotificationWithIcon({
            type: "error",
            title: "Error",
            message: "The username or password is incorrect"
          });
        }
      })
      .catch(err => {
        setLoading(false);
        openNotificationWithIcon({
          type: "error",
          title: "Error",
          message: "Something went wrong"
        });
        console.error("Login error ", err);
      });
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{ minHeight: "100vh", backgroundColor: "#496096" }}
    >
      <Col>
        <Card className="login-form-card" bordered={false}>
          <Spin
            size="large"
            style={{
              display: loading ? "block" : "none",
              position: "absolute",
              left: "50%",
              marginLeft: "-20px",
              top: "50%",
              marginTop: "-20px"
            }}
          />

          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              language: "English American"
            }}
            onFinish={handleSubmit}
            style={{ opacity: loading ? 0.2 : 1 }}
          >
            <Row justify="center">
              <Col>
                <img
                  alt="Logo"
                  src={logo}
                  style={{
                    width: "150px",
                    marginBottom: "24px",
                    opacity: loading ? 0.2 : 1
                  }}
                />
              </Col>
            </Row>

            <Form.Item
              name="j_username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!"
                }
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="j_password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!"
                }
              ]}
            >
              <Input.Password
                autoComplete="on"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Continue
              </Button>
            </Form.Item>
            <Form.Item name="language">
              <Select onChange={event => handleChangeLanguage(event)}>
                <Option value="en">English American</Option>
                {/*<Option value="ar">Arabic</Option>*/}
                {/*<Option value="zh">Chinese</Option>*/}
              </Select>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
