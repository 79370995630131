/*
 *    Avetti Commerce
 *
 *    NOTICE OF LICENSE
 *
 *    This source file is subject to the Avetti Enterprise License (AEL 1.20)
 *    that is bundled with this package in the file AELICENSE.txt.
 *
 *    Copyright(c)2020. Avetti.com Corporation. (http://www.avetticommerce.com)
 *    License:   Avetti Enterprise License (AEL 1.20)
 *
 *    COPYRIGHT Avetti.com Corporation 1998-2020.  All Rights Reserved
 */

import {
  UPDATE_INVENTORY_PRODUCT_INPUT_STATE,
  UPDATE_PRODUCT_INPUT_STATE,
  UPDATE_PRICE_PRODUCT_INPUT_STATE
} from "../types";

export const updateProduct = data => ({
  type: UPDATE_PRODUCT_INPUT_STATE,
  payload: data
});
